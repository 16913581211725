<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogQuestion"
        persistent
        max-width="1200px"
        scrollable
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_question === 'add'" class="headline"
                >Thêm câu hỏi (Question)</span
              >
              <span v-if="type_form_question === 'update'" class="headline"
                >Cập nhật câu hỏi (Question)</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuestion = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>

                <template v-if="canDisplayTitleQues()">
                  <v-col cols="12">
                    <template v-if="allowedToUsePhonetic()">
                      <PhoneticInput v-model="inputTitleQuestion" label="Tiêu đề" name-input-ref="inputTitleQuestion" />
                    </template>
                    <template v-else>
                      <label style="font-size: 16px">Tiêu đề</label>
                      <Editor v-model="inputTitleQuestion"></Editor>
                    </template>
                  </v-col>
                </template>

                <v-col cols="12" class="pt-4" v-if="canInputGenAudio()">
                  <div class="d-flex">
                    <label style="font-size: 16px">Audio file:</label>
                    <v-radio-group
                        v-model="audio_file_action_input"
                        row dense class="mt-0 pt-0 ml-1"
                    >
                      <v-radio label="Gen Audio" :value="audio_file_action_constant.GEN_AUDIO"></v-radio>
                      <v-radio label="Upload file" :value="audio_file_action_constant.UPLOAD"></v-radio>
                    </v-radio-group>
                  </div>
                  <v-row class="m-0" v-if="audio_file_action_input === audio_file_action_constant.GEN_AUDIO">
                    <v-col cols="2" class="text-center">
                      <v-btn
                          @click="btnGenAudioForTitleQuestion()"
                          outlined color="blue" class="mt-0">Gen Audio</v-btn>
                    </v-col>
                    <v-col cols="10">
                      <template>
                        <vuetify-audio
                            v-if="audio_data_input"
                            :file="audio_data_input"
                            color="success"
                        ></vuetify-audio>
                        <p v-if="!audio_data_input">(Không có dữ liệu audio)</p>
                      </template>
                    </v-col>
                  </v-row>
                  <div v-if="audio_file_action_input === audio_file_action_constant.UPLOAD">
                    <v-file-input
                        outlined dense
                        class="mt-0"
                        prepend-icon="mdi-music-note"
                        accept="audio/*"
                        label="Chọn audio sample"
                        @change="(e) => onAudioFileChange(e)"
                        :value="audio_file_input"
                    ></v-file-input>
                    <template>
                      <vuetify-audio
                          v-if="audio_data_input"
                          :file="audio_data_input"
                          color="success"
                      ></vuetify-audio>
                      <p v-if="!audio_data_input">(Không có dữ liệu audio)</p>
                    </template>
                  </div>
                </v-col>

                <v-col cols="12">
                  <template v-if="allowedToUsePhonetic()">
                    <PhoneticInput v-model="inputDesQuestion" label="Mô tả" name-input-ref="inputDesQuestion" />
                  </template>
                  <template v-else>
                    <label style="font-size: 16px">Mô tả</label>
                    <Editor v-model="inputDesQuestion"></Editor>
                  </template>
                </v-col>

                <v-col cols="12">
                  <label style="font-size: 16px"
                    >Thời gian suy nghĩ (phút : giây):
                  </label>
                  <date-picker
                    class="ml-1"
                    v-model="time_to_think_input"
                    type="time"
                    format="mm:ss"
                    value-type="format"
                    placeholder="mm:ss"
                  ></date-picker>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <label class="mt-1" style="font-size: 16px"
                    >Câu hỏi có được ghi chú hay không:
                  </label>
                  <v-radio-group
                    v-model="can_take_note_input"
                    row dense class="mt-0 pt-0 ml-1"
                    >
                    <v-radio label="Có" :value="1"></v-radio>
                    <v-radio label="Không" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
                <template v-if="data_type_question === exercise_type_constant.TRUE_FALSE_NOT_GIVEN">
                  <v-col cols="12" class="mt-0">
                    <YesNo
                      :correct="correctTrueFalse"
                      :option="optionAnswerTrueFalse"
                      @setCorrectAnswer="(e) => (correctTrueFalse = e)"
                    ></YesNo>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.YES_NO_NOT_GIVEN">
                  <v-col cols="12" class="mt-0">
                    <YesNo
                      :correct="correctYesNo"
                      :option="optionAnswerYesNo"
                      @setCorrectAnswer="(e) => (correctYesNo = e)"
                    ></YesNo>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SINGLE_CHOICE">
                  <SingleChoice
                    :hasConfigPhonetic="allowedToUsePhonetic()"
                    :option="optionAnswerSingleChoice"
                    :correct="correctSingleChoice"
                    @setCorrectAnswer="(e) => (correctSingleChoice = e)"
                    @setOptionAnswer="(e) => (optionAnswerSingleChoice = e)"
                  >
                  </SingleChoice>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SINGLE_SELECT">
                  <SingleSelect
                    :correct="correctSingleSelect"
                    :option="optionAnswerSingleSelect"
                    @setCorrectAnswer="(e) => (correctSingleSelect = e)"
                  ></SingleSelect>
                </template>
                <template v-if="data_type_question === exercise_type_constant.MULTIPLE_CHOICE">
                  <MultipleChoice
                    :hasConfigPhonetic="allowedToUsePhonetic()"
                    :option="optionAnswerMultipleChoice"
                    @setOptionAnswer="(e) => (optionAnswerMultipleChoice = e)"
                  ></MultipleChoice>
                </template>
                <template v-if="checkCanInputDirection()">

                    <template v-for="(option, iDir) in optionDirection">
                      <v-col cols="10" class="pt-0 pb-0" :key="iDir">
                        <v-text-field
                            v-model="option.content"
                            :placeholder="'Nhập direction '+ (iDir+1)"
                            dense outlined
                        />
                      </v-col>
                    </template>
                    <v-col cols="12" class="pt-0 pb-8">
                      <v-btn
                          @click="() => optionDirection.push({content: ''})"
                          outlined
                          class="font-weight-bold"
                          color="blue darken-1">
                          Thêm direction
                      </v-btn>
                    </v-col>
                </template>
                <template v-if="
                    [
                      exercise_type_constant.LONG_ANSWER,
                      exercise_type_constant.WRITING_NO_MARKING,
                    ].includes(data_type_question)
                  ">
                  <v-col cols="3">
                    <v-select
                      :items="weight_options"
                      item-text="label"
                      item-value="value"
                      label="Weight"
                      required filled dense
                      v-model="weight_paragraph"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="p-0"></v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Min words"
                      required
                      type="number"
                      v-model="min_words"
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.RECORDING">
                  <v-col cols="10" class="d-flex">
                    <label style="font-size: 16px" class="mt-1 p-0"
                      >Giới hạn thời gian (phút : giây):
                    </label>
                    <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="mm:ss"
                      value-type="format"
                      placeholder="mm:ss"
                    ></date-picker>
                  </v-col>
                  <v-col cols="10" class="d-flex">
                    <v-switch
                      v-model="is_required_script"
                      :label="
                        'Require script: ' +
                        (is_required_script === true ? 'Yes' : 'No')
                      "
                    ></v-switch>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.FILE_UPLOAD"></template>
                <template
                    v-if=" [
                        exercise_type_constant.INDIVIDUAL_SOUNDS,
                        exercise_type_constant.LONG_ANSWER,
                        exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT,
                    ].includes(data_type_question) &&
                    ![1].includes(skills[indexSkill]?.sections[indexSection].partConfig?.offset) &&
                    skills[indexSkill]?.sections[indexSection].partConfig?.module === module_section_part.VWR
                    ">
                  <v-col cols="10" class="d-flex">
                    <label style="font-size: 16px" class="mt-1 p-0"
                      >Giới hạn thời gian (phút : giây):
                    </label>
                    <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="HH:mm"
                      value-type="format"
                      placeholder="mm:ss"
                    ></date-picker>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.INDIVIDUAL_SOUNDS">
                  <v-col cols="10" class="d-flex">
                    <label style="font-size: 16px" class="mt-1 p-0"
                      >Giới hạn thời gian (phút : giây):
                    </label>
                    <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="HH:mm"
                      value-type="format"
                      placeholder="mm:ss"
                    ></date-picker>
                  </v-col>
                </template>
                <template v-if=" data_type_question === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_WORD_STRESSES">
                  <v-col cols="10" class="d-flex">
                    <label style="font-size: 16px" class="mt-1 p-0"
                      >Giới hạn thời gian (phút : giây):
                    </label>
                    <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="HH:mm"
                      value-type="format"
                      placeholder="mm:ss"
                    ></date-picker>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SENTENCE_STRESSES">
                  <SentenceStressed
                    :url_file="sample_data"
                    :file="sample_file"
                    :option="optionAnswerSentenceStressed"
                    @setOptionAnswer="(e) => (optionAnswerSentenceStressed = e)"
                    @setSampleData="setSampleData"
                    @setSampleFile="setSampleFile"
                  ></SentenceStressed>
                </template>
                <template v-if=" data_type_question === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES">
                  <SentenceStressed
                    :option="optionAnswerIndiSounSenStres"
                    @setOptionAnswer="(e) => (optionAnswerIndiSounSenStres = e)"
                  ></SentenceStressed>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SINGLE_CHOICE_EDITOR">
                  <SingleChoiceEditor
                    :hasConfigPhonetic="allowedToUsePhonetic()"
                    :option="optionSingleChoiceEditor"
                    @setOptionSingleChoiceEditor="setOptionSingleChoiceEditor"
                  ></SingleChoiceEditor>
                </template>

                <template
                  v-if="[
                      exercise_type_constant.INDIVIDUAL_SOUNDS,
                      exercise_type_constant.WORD_STRESSES,
                      exercise_type_constant.INDIVIDUAL_SOUNDS_AND_WORD_STRESSES,
                      exercise_type_constant.SENTENCE_STRESSES,
                      exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES,
                      exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT,
                      ].includes(data_type_question)"
                >
                  <v-col cols="12" class="p-0 m-0"></v-col>
                  <v-col cols="10" class="d-flex">
                    <label style="font-size: 16px" class="mt-3 p-0"
                      >Số lần tự động chấm bài:
                    </label>
                    <v-text-field
                      style="max-width: 150px"
                      class="ml-2"
                      dense
                      outlined
                      type="number"
                      min="0"
                      clearable
                      v-model="auto_marking_quota"
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SHADOWING">
                  <Shadowing
                    :karaoke_file_url="sample_data"
                    :transcript_audio_data="transcript_audio"
                    @setSampleData="(e) => (sample_data = e)"
                    @setTranscriptAudio="(e) => (transcript_audio = e)"
                  ></Shadowing>
                </template>

                <template v-if="data_type_question !== exercise_type_constant.SHADOWING">

                  <template v-if="data_type_question === exercise_type_constant.RECORDING && skills[indexSkill].skill_type === 'Speaking' ">
                    <v-col cols="6" class="pt-0">
                      <v-switch
                          v-model="has_config_limit_listen_time"
                          label="Giới hạn số lượng nghe câu hỏi:"
                      ></v-switch>
                    </v-col>
                    <v-col cols="6" class="pt-0 d-flex" v-if="has_config_limit_listen_time">
                      <label style="font-size: 16px" class="mt-4">Số lượt nghe cho phép:</label>
                      <v-text-field
                          v-model="limit_listen_time_input"
                          type="number"
                          outlined dense
                          class="mt-2 ml-2" style="max-width: 70px" />
                    </v-col>
                  </template>

                  <v-col cols="12" class="pt-0">
                    <label style="font-size: 16px">Nội dung trả lời mẫu</label>
                    <Editor v-model="sample_content_input"></Editor>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <template v-if="allowedToUsePhonetic()">
                      <PhoneticInput v-model="explanationQuestion" label="Explanation" name-input-ref="explanationQuestion" />
                    </template>
                    <template v-else>
                      <label style="font-size: 16px">Explanation</label>
                      <Editor v-model="explanationQuestion"></Editor>
                    </template>
                  </v-col>

                  <v-col cols="12" class="pt-4">
                    <div style="width: 100%">
                      <label class="float-left" style="font-size: 16px">Idea (Dùng cho test practice)</label>
                      <span
                          v-if="checkCanUserGetIdeaGeneratorFromOpenAi()"
                          @click="getIdeaGeneratorFromOpenAi()"
                          class="text-h6 float-right font-weight-bold blue--text cursor-pointer">
                        Xem gợi ý
                      </span>

                    </div>
                    <div style="width: 100%; clear: both">
                      <Editor v-model="idea_input"></Editor>
                    </div>
                  </v-col>

                  <v-col cols="12" class="pt-4">
                    <div style="width: 100%">
                      <label class="float-left" style="font-size: 16px">Sample (Dùng cho test practice)</label>
                      <span
                          v-if="checkCanUserGetIdeaGeneratorFromOpenAi()"
                          @click="getSampleGeneratorFromOpenAi()"
                          class="text-h6 float-right font-weight-bold blue--text cursor-pointer">
                        Xem gợi ý
                      </span>
                    </div>
                    <div style="width: 100%; clear: both">
                      <Editor v-model="sample_input"></Editor>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-4" v-if="canInputAudioSample()">
                    <div class="d-flex">
                      <label style="font-size: 16px">Audio sample:</label>
                      <v-radio-group
                          v-model="sample_file_action_input"
                          row dense class="mt-0 pt-0 ml-1"
                      >
                        <v-radio label="Gen Audio" :value="sample_file_action_constant.GEN_AUDIO"></v-radio>
                        <v-radio label="Upload file" :value="sample_file_action_constant.UPLOAD"></v-radio>
                      </v-radio-group>
                    </div>
                    <v-row class="m-0" v-if="sample_file_action_input === sample_file_action_constant.GEN_AUDIO">
                      <v-col cols="2" class="text-center">
                        <v-btn
                            @click="btnGenAudioForSampleQuestion()"
                            outlined color="blue" class="mt-0">Gen Audio</v-btn>
                      </v-col>
                      <v-col cols="10">
                        <template>
                          <vuetify-audio
                              v-if="sample_data"
                              :file="sample_data"
                              color="success"
                          ></vuetify-audio>
                          <p v-if="!sample_data">(Không có dữ liệu audio sample)</p>
                        </template>

                      </v-col>
                    </v-row>
                    <div v-if="sample_file_action_input === sample_file_action_constant.UPLOAD">
                      <v-file-input
                          outlined dense
                          class="mt-0"
                          prepend-icon="mdi-music-note"
                          accept="audio/*"
                          label="Chọn audio sample"
                          @change="(e) => onAudioSampleFileChange(e)"
                          :value="sample_file"
                      ></v-file-input>
                      <template>
                        <vuetify-audio
                            v-if="sample_data"
                            :file="sample_data"
                            color="success"
                        ></vuetify-audio>
                        <p v-if="!sample_data">(Không có dữ liệu audio sample)</p>
                      </template>
                    </div>
                  </v-col>
                </template>

                <v-col cols="12" class="pt-4">
                  <div style="width: 100%">
                    <label class="float-left" style="font-size: 16px">
                      Sample Vocabulary
                      <span class="red--text">( Chỉ dành cho nhập liệu VSR IELTS )</span>
                    </label>
                  </div>
                  <div style="width: 100%; clear: both">
                    <Editor v-model="sample_vocabulary_input"></Editor>
                  </div>
                </v-col>

                <v-col cols="12" class="m-0 p-0 pl-2">
                  <v-switch
                    v-model="toggle_element"
                    :label="`Thêm element: ${toggle_element.toString()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="m-0 p-0 pl-2" v-if="toggle_element">

                  <ElementChoose
                      :has-input-description-image="checkCanUseGetDesImage()"
                  />

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogQuestion = false"
              >Đóng</v-btn
            >
            <v-btn
              outlined
              class="font-weight-bold"
              color="blue darken-1"
              text
              v-if="type_form_question === 'add'"
              @click="btnAddQuestion"
              >Thêm</v-btn
            >
            <v-btn
              outlined
              class="font-weight-bold"
              color="blue darken-1"
              text
              v-if="type_form_question === 'update'"
              @click="btnEditQuestion"
              >Cập nhật</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import SingleChoiceEditor from "@/view/pages/tests/Modal/QuestionType/SingleChoiceEditor";
import SingleChoice from "@/view/pages/tests/Modal/QuestionType/SingleChoice";
import ideaGeneratorValidate from "./Validate/Question/ideaGeneratorValidate";
import sampleGeneratorValidate from "./Validate/Question/sampleGeneratorValidate";
import weightSupport from "./Support/Question/weightSupport";
import configPart from "./Support/Section/configPart";
import phoneticSupport from "./Support/Question/phoneticSupport";
import PhoneticInput from "./Support/Question/PhoneticInput";
import productCategorySupport from "./Support/Test/productCategorySupport";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";

export default {
  name: "QuestionModal",
  components: {
    PhoneticInput,
    Shadowing: () => import("@/view/pages/tests/Modal/QuestionType/Shadowing"),
    SentenceStressed: () => import("@/view/pages/tests/Modal/QuestionType/SentenceStressed"),
    MultipleChoice: () => import("@/view/pages/tests/Modal/QuestionType/MultipleChoice"),
    SingleSelect: () => import("@/view/pages/tests/Modal/QuestionType/SingleSelect"),
    SingleChoice,
    YesNo: () => import("@/view/pages/tests/Modal/QuestionType/YesNo"),
    SingleChoiceEditor,
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    ElementChoose: () => import("./ElementChoose"),
    VuetifyAudio: () => import("vuetify-audio"),
  },
  mixins: [
      ideaGeneratorValidate,
      sampleGeneratorValidate,
      weightSupport,
      configPart,
      phoneticSupport,
      productCategorySupport,
      constantTestBuilder,
  ],
  data() {
    return {
      data_type_question: null,
      inputTitleQuestion: null,
      inputDesQuestion: "",
      correctYesNo: null,
      correctTrueFalse: null,
      correctSingleChoice: null,
      correctSingleSelect: null,
      correctOptShortAn: null,
      optionAnswerTrueFalse: ["True", "False", "Not Given"],
      optionAnswerYesNo: ["Yes", "No", "Not Given"],
      optionAnswerSingleSelect: ["", ""],
      optionAnswerSingleChoice: [{ value: "" }, { value: "" }],
      optionAnswerMultipleChoice: [{ value: "", checked: false }, { value: "", checked: false },],
      optionAnswerSentenceStressed: { correct: "", text: "" },
      optionAnswerIndiSounSenStres: { correct: "", text: "" },
      optionSingleChoiceEditor: [{ value: "", checked: false }, { value: "", checked: false },],
      explanationQuestion: "",
      // weight_options: ['Writing Task 1', 'Writing Task 2', 'TOEIC Writing'],
      weight_paragraph: null,
      min_words: null,
      limit_time: null,
      toggle_element: false,
      is_required_script: false,
      auto_marking_quota: null,
      sample_data: null,
      sample_file: null,
      transcript_audio: {},
      sample_content_input: "",
      time_to_think_input: null,
      can_take_note_input: 0,
      sample_input: "",
      idea_input: "",
      offsetSortQuestion: 0,
      has_config_limit_listen_time: false,
      limit_listen_time_input: null,
      skillCurrent: null,
      optionDirection: [{ content: "" }, { content: "" }],
      audio_data_input: null,
      audio_file_input: null,
      audio_file_action_input: 1,
      audio_file_action_constant: {
        UPLOAD: 1,
        GEN_AUDIO: 2,
      },
      sample_file_action_input: 1,
      sample_file_action_constant: {
        UPLOAD: 1,
        GEN_AUDIO: 2,
      },
      sample_vocabulary_input: "",
    };
  },
  computed: {
    ...mapState({
      type_form_question: (state) => state.contentTestBuilderStore.type_form_question,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      question_id: (state) => state.contentTestBuilderStore.question_id,
      answer_id: (state) => state.contentTestBuilderStore.answer_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      indexQuestion: (state) => state.contentTestBuilderStore.indexQuestion,
      // weight_options: (state) => state.contentTestBuilderStore.listWeight,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogQuestion", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_post_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageFile",
          value
        );
      },
    },
    element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementId", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setVideoElementData",
          value
        );
      },
    },
    element_description: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_description;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementDescription", value);
      },
    },
    dialogAddPhoneticToTitleQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogAddPhoneticToTitleQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogAddPhoneticToTitleQuestion", value);
      },
    },
    refTitleQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.refTitleQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setRefTitleQuestion", value);
      },
    },
  },
  watch: {
    dialogQuestion: function (val) {
      if (val) {
        this.setDataInput(this.type_form_question);
        if (this.type_form_question === 'add') {
          let validateQuestionLimit = this.validateQuestionLimit();
          if (!validateQuestionLimit) {
            this.$nextTick(() => {
              this.dialogQuestion = false;
            })
          }
        }
      } else {
        this.sample_data = null;
        this.sample_file = null;
      }
    },
    // dialogAddPhoneticToTitleQuestion(val) {
    //   if (!val) {
    //     this.$refs.refTitleQuestion.$refs.editorCustom.$_instance.editing.view.focus();
    //   }
    // }
  },
  methods: {
    setDataInput(type) {
      this.skillCurrent = this.skills[this.indexSkill].skill_type;
      this.audio_file_action_input = this.audio_file_action_constant.GEN_AUDIO
      this.sample_file_action_input = this.sample_file_action_constant.GEN_AUDIO
      if (type === "add") {
        this.data_type_question = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type;
        console.log(this.data_type_question);
        this.inputTitleQuestion = "";
        this.inputDesQuestion = "";
        this.correctYesNo = null;
        this.correctTrueFalse = null;
        this.correctSingleChoice = null;
        this.correctSingleSelect = null;
        this.correctOptShortAn = null;
        this.optionAnswerSingleSelect = ["", ""];
        this.optionAnswerSingleChoice = [{ value: "" }, { value: "" }, { value: "" },];
        this.optionAnswerMultipleChoice = [{ value: "", checked: false }, { value: "", checked: false },];
        this.optionAnswerSentenceStressed = { correct: "", text: "" };
        this.optionAnswerIndiSounSenStres = { correct: "", text: "" };
        this.optionSingleChoiceEditor = [{ value: "", checked: false }, { value: "", checked: false },];
        this.explanationQuestion = "";
        this.weight_paragraph = this.getValueByLabelWeight("Writing Task 1");
        this.limit_time = this.getTimeLimitInputDefault();
        this.min_words = null;
        this.toggle_element = false;
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_id = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.is_required_script = false;
        this.auto_marking_quota = null;
        this.sample_data = null;
        this.sample_file = null;
        this.transcript_audio = {transcript: [], words: [],};
        this.sample_content_input = "";
        this.time_to_think_input = this.getTimeToThinkInputDefault();
        this.can_take_note_input = this.getCanTakeNotInputDefault();
        this.idea_input = "";
        this.sample_input = "";
        this.offsetSortQuestion = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].questions.length + 1;
        this.has_config_limit_listen_time = false;
        this.limit_listen_time_input = null;
        this.element_description = null;
        this.optionDirection = [{ content: "" }, { content: "" }];
        this.audio_data_input = null;
        this.audio_file_input = null;
        this.sample_vocabulary_input = "";
      } else {
        let data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].questions[this.indexQuestion];
        data = JSON.parse(JSON.stringify(data));
        console.log(data);
        // let type = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type;
        let type = data.type;
        this.data_type_question = type;
        this.inputTitleQuestion = data.title === null ? "" : data.title;
        this.inputDesQuestion = data.description == null ? "" : data.description;
        if (type === this.exercise_type_constant.MULTIPLE_CHOICE) {
          this.optionAnswerMultipleChoice = this.convertDisplayMultipleChoice(data.answers[0].option, data.answers[0].correct);
        } else if (type === this.exercise_type_constant.TRUE_FALSE_NOT_GIVEN) {
          this.correctTrueFalse = data.answers[0].correct.toString();
        } else if (type === this.exercise_type_constant.YES_NO_NOT_GIVEN) {
          this.correctYesNo = data.answers[0].correct.toString();
        } else if (type === this.exercise_type_constant.SINGLE_CHOICE) {
          this.optionAnswerSingleChoice = JSON.parse(JSON.stringify(data.answers[0].option));
          this.correctSingleChoice = data.answers[0].correct;
        } else if (type === this.exercise_type_constant.SINGLE_SELECT) {
          this.optionAnswerSingleSelect = JSON.parse(JSON.stringify(data.answers[0].option));
          this.correctSingleSelect = data.answers[0].correct;
        } else if (type === this.exercise_type_constant.LONG_ANSWER || type === this.exercise_type_constant.WRITING_NO_MARKING) {
          this.weight_paragraph = data.weight;
        }
        if (type === this.exercise_type_constant.SENTENCE_STRESSES) {
          this.optionAnswerSentenceStressed = JSON.parse(JSON.stringify(data.body));
        }
        if (type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES) {
          this.optionAnswerIndiSounSenStres = JSON.parse(JSON.stringify(data.body));
        }
        if (type === this.exercise_type_constant.SINGLE_CHOICE_EDITOR) {
          this.optionSingleChoiceEditor = JSON.parse(JSON.stringify(data.answers[0].option)).map((option) => {
            option.value = this.encodeHTMLForChineseCharacter(option.value);
            return option;
          });
        }
        if (type === this.exercise_type_constant.SHADOWING) {
          this.transcript_audio = JSON.parse(JSON.stringify(data.body));
        }
        this.min_words = this.checkDisplayValidationRules(data, 'min_words');
        // this.limit_time = this.checkDisplayValidationRules(data, 'limit_time');
        this.limit_time = data.limit_time ? this.$options.filters.convertSecondToMS(data.limit_time ) : null;
        this.explanationQuestion = data.explanation == null ? "" : data.explanation;
        this.type_element = data.element_type;
        this.element_post_data = data.element_type === "Post" ? data.element_data : "";
        this.element_image_data = data.element_type === "Image" ? data.element_data : null;
        this.element_audio_data = data.element_type === "Audio" ? data.element_data : null;
        this.video_element_data = data.element_type === "Video" ? data.element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_description = data.element_description ?? '';
        this.element_id = this.checkElementId(data.element_type, data.element_id);
        this.video_element_id = data.element_type === "Video" ? data.element_id : null;
        this.toggle_element = data.is_element;
        this.is_required_script = data.is_required_script == 1;
        this.auto_marking_quota = data.auto_marking_quota;
        this.sample_data = data.sample_file ?? null;
        this.sample_file = null;
        this.sample_content_input = data.sample_content === undefined || data.sample_content == null ? "" : data.sample_content;
        this.sample_input = data.sample === undefined || data.sample == null ? "" : data.sample;
        this.idea_input = data.idea === undefined || data.idea == null ? "" : data.idea;
        this.time_to_think_input = data.time_to_think === undefined || data.time_to_think == null ? null : this.$options.filters.convertSecondToMS(data.time_to_think);
        this.can_take_note_input = data.can_take_note == null ? 0 : data.can_take_note;
        this.has_config_limit_listen_time = !this.$utils.isValueInvalid(data.limit_listen_time);
        this.limit_listen_time_input = data.limit_listen_time ?? null;
        this.optionDirection = data.direction ? data.direction : [{ content: "" }, { content: "" }];
        this.sample_vocabulary_input = data.sample_vocabulary ?? '';
        if (this.allowedToUsePhonetic()) {
          this.inputTitleQuestion = this.encodeHTMLForChineseCharacter(this.inputTitleQuestion);
          this.inputDesQuestion = this.encodeHTMLForChineseCharacter(this.inputDesQuestion);
          this.explanationQuestion = this.encodeHTMLForChineseCharacter(this.explanationQuestion);
        }
        this.audio_data_input = data.audio_file ?? null;
        this.audio_file_input = null;
      }

    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type === "Video") {
        check_element_id = null;
      }
      return check_element_id;
    },
    checkDisplayValidationRules(question, field) {
      let data = null;
      if (question.validation_rules) {
        if (question.validation_rules[field]) {
          if(field === 'limit_time') {
            data = this.$options.filters.convertSecondToMS(question.validation_rules[field]);
          } else {
            data = question.validation_rules[field];
          }
        }

      }
      return data;
    },
    convertDisplayMultipleChoice(option, correct) {
      let data = [];
      for (let iO = 0; iO < option.length; iO++) {
        data[iO] = {
          value: option[iO],
          checked: false,
        };
        for (let iC = 0; iC < correct.length; iC++) {
          if (correct[iC].index === iO) {
            data[iO].checked = true;
          }
        }
      }
      return data;
    },
    validateElementQuestion() {
      let flat = true;
      if (this.toggle_element) {
        if (this.type_element == null) {
          this.$toasted.error("Hãy chọn loại element !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (this.type_element === "Video") {
          if (this.video_element_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Image") {
          if (this.element_image_data == null) {
            this.$toasted.error("Bạn chưa chọn tập tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
          if (this.element_description) {
            if (this.element_description.replaceAll('\n', '').length > 3000) {
              this.$toasted.error("Mô tả ảnh không được nhập quá 3000 ký tự", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              flat = false;
            }
          }
        }
        if (this.type_element === "Audio") {
          if (this.element_audio_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Post") {
          if (this.element_post_data == null) {
            this.$toasted.error("Bạn chưa nhập dữ liệu !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      return flat;
    },
    validateQuestionLimit() {
      let flat = true;
      let currentSection = this.skills[this.indexSkill].sections[this.indexSection];
      if (currentSection.question_limit != null) {
        let countQuestionBySection = currentSection.exercises.reduce((totalQuestion, exercise) => {
          let questionLength = exercise.questions !== undefined ? exercise.questions.length : 0;
          return totalQuestion + questionLength;
        },0);
        if (countQuestionBySection >= currentSection.question_limit) {
          this.$toasted.error("Số câu hỏi tối đa của phần này là "+currentSection.question_limit+" câu", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      return flat;
    },
    async btnAddQuestion() {
      let vm = this;
      let validateQuestionLimit = this.validateQuestionLimit();
      if (!validateQuestionLimit) {
        return;
      }
      let validatorElement = this.validateElementQuestion();
      if (!validatorElement) {
        return;
      }
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }
      let validateQues = this.validateQuestionByType(this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type);
      if (!validateQues) {
        return;
      }

      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        let valueElement = vm.getValueElement(vm.type_element);
        if (vm.type_element === "Video") {
          vm.element_id = vm.video_element_id;
          vm.is_call_api = false;
        } else {
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
            formData.append("editor", vm.checkElementData(vm.type_element));
          }
          formData.append("file", vm.checkElementFile(vm.type_element));
          formData.append("description", vm.element_description);
          await ApiService.post(
            "prep-app/test/draft/exercise/upload-element",
            formData
          )
            .then(function (response) {
              vm.element_id = response.data.element_code;
              vm.is_call_api = false;
            })
            .catch(function (error) {
              if (error) {
                vm.dialogQuestion = false;
                vm.is_call_api = false;
                vm.$toasted.error("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
            });
        }
      }

      let dataAnswer = {
        option: this.dataAnswer(this.data_type_question).optionAnswer,
        correct: this.dataAnswer(this.data_type_question).correctAnswer,
      };

      let data = {
        exercise_id: this.exercise_id,
        exercise_type: this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type,
        type: this.data_type_question,
        title: this.checkSaveTitleQuestion(this.data_type_question),
        description: this.inputDesQuestion,
        weight: 0,
        validation_rules: this.checkSaveValidationRules(),
        body: this.checkSaveDataBody(this.data_type_question),
        element_type: this.type_element,
        element_data: this.checkElementData(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        is_element: this.toggle_element,
        is_required_script: this.is_required_script === true ? 1 : 0,
        sample_data: this.sample_data ?? null,
        sample_file: this.sample_file ?? null,
        sample_content: this.sample_content_input,
        idea: this.idea_input,
        sample: this.sample_input,
        can_take_note: this.can_take_note_input,
        index: this.offsetSortQuestion,
        limit_listen_time: this.limit_listen_time_input,
        audio_file: this.audio_file_input ?? null,
        audio_data: this.audio_data_input ?? null,
        audio_file_text: null,
        sample_file_text: null,
        limit_time: this.limit_time == null ? null : this.$options.filters.convertMSToSecond(this.limit_time),
        direction: this.optionDirection.filter((e) => e.content),
        sample_vocabulary: this.sample_vocabulary_input.trim(),
        answers: [],
      };
      if (this.time_to_think_input !== null)
        data.time_to_think = this.$options.filters.convertMSToSecond(this.time_to_think_input);

      if (this.explanationQuestion !== "") {
        data.explanation = this.explanationQuestion;
      }
      if (this.auto_marking_quota !== "" || this.auto_marking_quota != null) {
        data.auto_marking_quota = this.auto_marking_quota;
      }
      if (this.data_type_question === this.exercise_type_constant.LONG_ANSWER || this.data_type_question === this.exercise_type_constant.WRITING_NO_MARKING) {
        data.weight = this.weight_paragraph;
      }

      if (this.product_category_id === this.product_category_constant.TOEIC) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          let partSection = this.skills[this.indexSkill].sections[this.indexSection].part;
          if ([3,4,5].includes(partSection)) {
            data.audio_file_text = this.checkSaveTitleQuestion(this.data_type_question);
          }
          data.sample_file_text = this.sample_input;
        }
      }
      if(this.allowedToUsePhonetic()) {
        data.title = this.decodeHTMLForChineseCharacter(data.title);
        data.description = this.decodeHTMLForChineseCharacter(data.description);
        if (data.explanation) {
          data.explanation = this.decodeHTMLForChineseCharacter(data.explanation);
        }
      }
      data.answers.push(dataAnswer);
      this.is_call_api = true;
      // if(this.is_call_api) {
      //   console.log(data);
      //   this.is_call_api = false;
      //   return;
      // }
      if ([
        this.exercise_type_constant.RECORDING,
        this.exercise_type_constant.SENTENCE_STRESSES,
        ].includes(this.data_type_question)
      ) {
        this.createWithFormData(data);
        return;
      }
      await ApiService.post("prep-app/test/draft/question", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.handleAfterCreate(data, res);
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestion = false;
            vm.is_call_api = false;
            vm.$toasted.error("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          }
        });
    },
    async btnEditQuestion() {
      let vm = this;
      let validatorElement = this.validateElementQuestion();
      if (!validatorElement) {
        return;
      }
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }

      let validate = this.validateQuestionByType(this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type);
      if (!validate) {
        return;
      }

      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        let valueElement = vm.getValueElement(vm.type_element);
        if (vm.type_element === "Video") {
          vm.element_id = vm.video_element_id;
          vm.is_call_api = false;
        } else {
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
            formData.append("editor", vm.checkElementData(vm.type_element));
          }
          formData.append("file", vm.checkElementFile(vm.type_element));
          formData.append("description", vm.element_description);
          await ApiService.post(
            "prep-app/test/draft/exercise/upload-element",
            formData
          )
            .then(function (response) {
              vm.element_id = response.data.element_code;
              vm.is_call_api = false;
            })
            .catch(function (error) {
              if (error) {
                vm.dialogQuestion = false;
                vm.is_call_api = false;
                vm.$toasted.error("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
            });
        }
      }

      let dataAnswer = {
        option: this.dataAnswer(this.data_type_question).optionAnswer,
        correct: this.dataAnswer(this.data_type_question).correctAnswer,
      };
      let data = {
        id: this.question_id,
        exercise_id: this.exercise_id,
        exercise_type: this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type,
        type: this.data_type_question,
        title: this.checkSaveTitleQuestion(this.data_type_question),
        description: this.inputDesQuestion,
        answers: [],
        validation_rules: this.checkSaveValidationRules(),
        body: this.checkSaveDataBody(this.data_type_question),
        element_type: this.type_element,
        element_data: this.checkElementData(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        is_element: this.toggle_element,
        answer_id: this.answer_id,
        is_required_script: this.is_required_script === true ? 1 : 0,
        sample_data: this.sample_data ?? null,
        sample_file: this.sample_file ?? null,
        sample_content: this.sample_content_input,
        idea: this.idea_input,
        sample: this.sample_input,
        can_take_note: this.can_take_note_input,
        limit_listen_time: this.limit_listen_time_input,
        limit_time: this.limit_time == null ? null : this.$options.filters.convertMSToSecond(this.limit_time),
        direction: this.optionDirection.filter((e) => e.content),
        audio_file: this.audio_file_input ?? null,
        audio_data: this.audio_data_input ?? null,
        sample_vocabulary: this.sample_vocabulary_input.trim(),
      };
      if (this.time_to_think_input !== null) data.time_to_think = this.$options.filters.convertMSToSecond(this.time_to_think_input);

      if (this.explanationQuestion !== "") {
        data.explanation = this.explanationQuestion;
      }
      if (this.auto_marking_quota !== "" || this.auto_marking_quota != null) {
        data.auto_marking_quota = this.auto_marking_quota;
      }
      if (this.data_type_question === this.exercise_type_constant.LONG_ANSWER || this.data_type_question === this.exercise_type_constant.WRITING_NO_MARKING) {
        data.weight = this.weight_paragraph;
      }
      data.answers.push(dataAnswer);
      if ([
        this.exercise_type_constant.RECORDING,
        this.exercise_type_constant.SENTENCE_STRESSES,
      ].includes(this.data_type_question)
      ) {
        this.updateWithFormData(data);
        return;
      }
      if(this.allowedToUsePhonetic()) {
        data.title = this.decodeHTMLForChineseCharacter(data.title);
        data.description = this.decodeHTMLForChineseCharacter(data.description);
        if (data.explanation) {
          data.explanation = this.decodeHTMLForChineseCharacter(data.explanation);
        }
      }
      this.is_call_api = true;
      // if (this.is_call_api) {
      //   this.is_call_api = false;
      //   return;
      // }
      await ApiService.put("prep-app/test/draft/question/" + this.question_id, data)
        .then(function (res) {
          if (res.status === 200) {
            if (res.data?.direction) {
              data.direction = res.data.direction;
            }
            data.audio_file = res.data.question.audio_file;
            data.sample_file = res.data.question.sample_file;
            vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[vm.indexQuestion] = data;
            vm.$set(vm.skills);
            vm.is_call_api = false;
            vm.dialogQuestion = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestion = false;
            vm.is_call_api = false;
            vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
          }
        });
    },
    checkElementData(type) {
      if (type === "Audio") {
        return this.element_audio_data;
      }
      if (type === "Image") {
        return this.element_image_data;
      }
      if (type === "Post") {
        return this.element_post_data;
      }
      if (type === "Video") {
        return this.video_element_data;
      }

      return null;
    },
    checkElementFile(type) {
      if (type === "Image") {
        return this.element_image_file;
      }
      if (type === "Audio") {
        return this.element_audio_file;
      }
      return null;
    },
    checkSaveTitleQuestion(type) {
      let title = this.inputTitleQuestion;
      if (type === this.exercise_type_constant.SENTENCE_STRESSES) {
        title = this.transformerOptionSentenceStressed(
          this.optionAnswerSentenceStressed
        ).text;
      }
      if (type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES) {
        title = this.transformerOptionSentenceStressed(
          this.optionAnswerIndiSounSenStres
        ).text;
      }

      return title;
    },
    checkSaveValidationRules() {
      let data = {
        min_words: null,
        limit_time: null
      };
      // let type = this.data_type_question;
      // if (
      //   type === "Recording" ||
      //   type === "Auto Marking Individual Sounds" ||
      //   type === "Auto Marking Word Stresses" ||
      //   type === "Auto Marking Individual Sounds & Word Stresses"
      // ) {
      //   data = {
      //     limit_time: this.limit_time == null ? null : this.$options.filters.convertMSToSecond(this.limit_time),
      //   };
      // }
      // if (type === this.exercise_type_constant.LONG_ANSWER || type === this.exercise_type_constant.WRITING_NO_MARKING) {
      //   data = {
      //     min_words: this.min_words,
      //   };
      // }
      if (this.limit_time) {
        data.limit_time = this.$options.filters.convertMSToSecond(this.limit_time);
      }
      if (this.min_words) {
        data.min_words = this.min_words;
      }

      return data;
    },
    validateFormQuestion() {
      let flat = true;
      let skill = this.skills[this.indexSkill];
      let exercise = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise];
      if (this.canDisplayTitleQues(this.data_type_question)) {
        if (this.inputTitleQuestion == null || this.inputTitleQuestion === "") {
          if (isRequiredTitle(this.product_category_id, this.product_category_constant, this.exercise_type_constant)) {
            this.messError("Bạn chưa nhập tiêu đề question");
            flat = false;
          } else {
            this.$toasted.show("Bạn chưa nhập tiêu đề question !!", {theme: "bubble", position: "top-right", duration: 4000,});
          }
        }
      }
      if (this.has_config_limit_listen_time && this.limit_listen_time_input && !this.$utils.isPositiveInteger(this.limit_listen_time_input)) {
        this.$toasted.error("Số lượt nghe câu hỏi phải là số nguyên âm", {theme: "bubble", position: "top-right", duration: 4000,});
        flat = false;
      }

      if (exercise.interactive) {
        if (exercise.interactive_config && exercise.interactive_config.display_explanation) {
          this.$toasted.show("Giải thích đáp án dạng video sẽ được ưu tiên hiển thị !!", {theme: "bubble", position: "top-right", duration: 4000,});
        }
      }

      if (this.product_category_id === this.product_category_constant.TOEIC) {
        if (skill.skill_type === 'Writing' && skill.vwr === 1) {
          if (this.weight_paragraph !== this.getValueByLabelWeight('TOEIC Writing')) {
            this.$toasted.error("Vui lòng chọn thông tin weight phù hợp với bài test VWR TOEIC", {theme: "bubble", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      if (this.canInputGenAudio()) {
        if (!this.audio_data_input) {
          this.messError("Bạn chưa gen audio câu hỏi");
          flat = false;
        }
        if (this.audio_file_input) {
          if (this.audio_file_input.size > 2097152) {
            this.messError("Audio file phải nhỏ hơn 2MB");
            flat = false;
          }
          let extAudioFile = this.$utils.getExtensionByFile(this.audio_file_input);
          if (!['mp3'].includes(extAudioFile)) {
            this.messError('File audio phải có định dạng là mp3');
            flat = false;
          }
        }
        if (!this.sample_data) {
          this.messError("Bạn chưa có thông tin audio bài mẫu");
          flat = false;
        }
      }
      if (this.sample_file) {
        if (this.sample_file.size > 5242880) {
          this.messError("File sample phải nhỏ hơn 5MB");
          flat = false;
        }
      }
      function isRequiredTitle(product_category_id, product_category_constant, exercise_type_constant) {
        let flat = false;
        if (product_category_id === product_category_constant.IELTS) {
          if (exercise.exercise_type === exercise_type_constant.RECORDING) {
            flat = true;
          }
        }
        return flat;
      }

      return flat;
    },
    validateQuestionByType(type) {
      let flat = true;
      if (type === this.exercise_type_constant.MULTIPLE_CHOICE) {
        for (let i = 0; i < this.optionAnswerMultipleChoice.length; i++) {
          if (this.isValueInvalid(this.optionAnswerMultipleChoice[i].value)) {
            this.$toasted.error("Hãy nhập đủ thông tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.TRUE_FALSE_NOT_GIVEN) {
        if (this.isValueInvalid(this.correctTrueFalse)) {
          this.$toasted.error("Hãy chọn 1 đáp án !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (type === this.exercise_type_constant.YES_NO_NOT_GIVEN) {
        if (this.isValueInvalid(this.correctYesNo)) {
          this.$toasted.error("Hãy chọn 1 đáp án !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (type === this.exercise_type_constant.SINGLE_CHOICE) {
        for (let i = 0; i < this.optionAnswerSingleChoice.length; i++) {
          if (this.isValueInvalid(this.optionAnswerSingleChoice[i].value)) {
            this.$toasted.error("Các option không được để trống !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.isValueInvalid(this.correctSingleChoice)) {
          this.$toasted.error("Hãy chọn 1 đáp án !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (type === this.exercise_type_constant.SINGLE_SELECT) {
        for (let i = 0; i < this.optionAnswerSingleSelect.length; i++) {
          if (this.isValueInvalid(this.optionAnswerSingleSelect[i])) {
            this.$toasted.error("Các option không được để trống !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.isValueInvalid(this.correctSingleSelect)) {
          this.$toasted.error("Hãy chọn 1 đáp án !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (type === this.exercise_type_constant.SENTENCE_STRESSES) {
        if (this.isValueInvalid(this.optionAnswerSentenceStressed.correct)) {
          this.$toasted.error("Hãy nhập dữ liệu cho question !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if ((this.optionAnswerSentenceStressed.correct.match(/{{(.*?)}}/g) || []).length <= 0) {
            this.$toasted.error("Phải có tối thiểu 1 giá trị !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.sample_file != null) {
          if (!this.sample_file.type.match("audio/mpeg")) {
            this.$toasted.error("File audio phải có định dạng là mp3 !", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES) {
        if (this.isValueInvalid(this.optionAnswerIndiSounSenStres.correct)) {
          this.$toasted.error("Hãy nhập dữ liệu cho question !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if ((this.optionAnswerIndiSounSenStres.correct.match(/{{(.*?)}}/g) || []).length <= 0) {
            this.$toasted.error("Phải có tối thiểu 1 giá trị !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.SINGLE_CHOICE_EDITOR) {
        for (let i = 0; i < this.optionSingleChoiceEditor.length; i++) {
          if (this.isValueInvalid(this.optionSingleChoiceEditor[i].value)) {
            this.$toasted.error("Hãy nhập dữ liệu cho option " + (i + 1) + " !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        let checkCorrect = this.optionSingleChoiceEditor.filter(function ($item) {
          return $item["checked"] === true;
        });
        if (checkCorrect.length <= 0) {
          this.$toasted.error("Hãy chọn đáp án đúng !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (type === this.exercise_type_constant.SHADOWING) {
        if (this.isValueInvalid(this.sample_data)) {
          this.$toasted.error("Hãy chọn file (mp3) !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (Object.keys(this.transcript_audio).length > 0) {
          if (this.transcript_audio.words.length <= 0) {
            this.$toasted.error("Hãy transcript file !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      return flat;
    },
    dataAnswer(exercise_type) {
      let optionAnswer = null,
        correctAnswer = null;

      if (exercise_type === this.exercise_type_constant.TRUE_FALSE_NOT_GIVEN) {
        optionAnswer = ["True", "False", "Not Given"];
        correctAnswer = this.correctTrueFalse;
      } else if (exercise_type === this.exercise_type_constant.YES_NO_NOT_GIVEN) {
        optionAnswer = ["Yes", "No", "Not Given"];
        correctAnswer = this.correctYesNo;
      } else if (exercise_type === this.exercise_type_constant.SINGLE_CHOICE) {
        optionAnswer = this.optionAnswerSingleChoice;
        correctAnswer = this.correctSingleChoice;
      } else if (exercise_type === this.exercise_type_constant.SINGLE_SELECT) {
        optionAnswer = this.optionAnswerSingleSelect;
        correctAnswer = this.correctSingleSelect;
      } else if (exercise_type === this.exercise_type_constant.MULTIPLE_CHOICE) {
        let convert = this.convertInputDataMultipleChoice(
          this.optionAnswerMultipleChoice
        );
        optionAnswer = convert.dataConvertOption;
        correctAnswer = convert.dataConvertCorrect;
      } else if (exercise_type === this.exercise_type_constant.SINGLE_CHOICE_EDITOR) {
        let objCp = JSON.parse(JSON.stringify(this.optionSingleChoiceEditor));
        optionAnswer = objCp.map((option) => {
          option.value = this.decodeHTMLForChineseCharacter(option.value);
          return option;
        });
        correctAnswer = [];
      }
      return {
        optionAnswer,
        correctAnswer,
      };
    },
    convertInputDataMultipleChoice(data) {
      let dataConvertOption = [];
      let dataConvertCorrect = [];
      for (let i = 0; i < data.length; i++) {
        dataConvertOption.push(data[i].value);
        if (data[i].checked) {
          dataConvertCorrect.push({
            value: data[i].value,
            index: i,
          });
        }
      }
      return {
        dataConvertOption,
        dataConvertCorrect,
      };
    },
    checkSaveDataBody(type) {
      let body = null;
      if (type === this.exercise_type_constant.SENTENCE_STRESSES) {
        body = this.transformerOptionSentenceStressed(
          this.optionAnswerSentenceStressed
        );
      }
      if (type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES) {
        body = this.transformerOptionSentenceStressed(
          this.optionAnswerIndiSounSenStres
        );
      }
      if (type === this.exercise_type_constant.SHADOWING) {
        body = this.transcript_audio;
      }
      return body;
    },
    transformerOptionSentenceStressed(option) {
      let text = option.correct;
      text = text.replaceAll("{{", "");
      text = text.replaceAll("}}", "");
      option.text = text;
      return option;
    },
    canDisplayTitleQues(type) {
      let flat = true;
      if (type === this.exercise_type_constant.SENTENCE_STRESSES) {
        flat = false;
      }
      if (type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES) {
        flat = false;
      }
      return flat;
    },
    getValueElement(type) {
      let valueElement;
      if (type === "Audio") valueElement = 7;
      else if (type === "Image") valueElement = 2;
      else if (type === "Video") valueElement = 5;
      else if (type === "Embed_yt") valueElement = 4;
      else if (type === "Post") valueElement = 6;
      else if (type === "PDF_file") valueElement = 8;
      return valueElement;
    },
    setOptionSingleChoiceEditor(data) {
      this.optionSingleChoiceEditor = data;
    },
    setSampleData(e) {
      this.sample_data = e;
    },
    setSampleFile(e) {
      this.sample_file = e;
    },
    createWithFormData(data) {
      let vm = this;
      let formData = new FormData();
      formData.append("id", data.id);
      formData.append("exercise_id", data.exercise_id);
      formData.append("exercise_type", data.exercise_type);
      formData.append("type", data.type);
      formData.append("title", data.title);
      formData.append("element_type", data.element_type);
      formData.append("element_data", data.element_data);
      formData.append("is_element", data.is_element);
      formData.append("is_required_script", data.is_required_script);
      formData.append("body", JSON.stringify(data.body));
      if (!(data.element_id == null))
        formData.append("element_id", data.element_id);
      if (!(data.description == null))
        formData.append("description", data.description);
      if (!(data.explanation == null))
        formData.append("explanation", data.explanation);
      if (!(data.auto_marking_quota == null))
        formData.append("auto_marking_quota", data.auto_marking_quota);
      if (data.validation_rules) formData.append("validation_rules", JSON.stringify(data.validation_rules));
      if (data.idea) formData.append("idea", data.idea);
      if (data.sample) formData.append("sample", data.sample);
      if (data.audio_data) formData.append("audio_data", data.audio_data);
      if (data.audio_file) formData.append("audio_file", data.audio_file);
      if (data.time_to_think) formData.append("time_to_think", data.time_to_think);
      if (data.can_take_note) formData.append("can_take_note", data.can_take_note);
      if (data.limit_time) formData.append("limit_time", data.limit_time);
      if (data.limit_listen_time) formData.append("limit_listen_time", data.limit_listen_time);
      if (data.sample_data) formData.append("sample_data", data.sample_data);
      if (data.sample_file) formData.append("sample_file", data.sample_file);
      if (data.sample_vocabulary) formData.append("sample_vocabulary", data.sample_vocabulary);

      ApiService.post("prep-app/test/draft/question", formData)
        .then(function (res) {
          if (res.status === 200) {
            vm.handleAfterCreate(data, res);
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestion = false;
            vm.is_call_api = false;
            vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ")
          }
        });
    },
    handleAfterCreate(data, res) {
      let vm = this;
      let checkQuestion = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions;
      if (checkQuestion === undefined || checkQuestion === null) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = [];
      }
      data.id = res.data.question_id;
      data.answer_id = res.data.answer_id;
      data.direction = res.data.direction;
      data.audio_file = res.data.question.audio_file;
      data.sample_file = res.data.question.sample_file;
      let indexRowQuestion = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.push(data) - 1;
      for (let indexExer = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length - 1; indexExer >= vm.indexExercise + 1; indexExer--) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question++;
      }
      for (let indexSec = vm.skills[vm.indexSkill].sections.length - 1; indexSec >= vm.indexSection + 1; indexSec--) {
        vm.skills[vm.indexSkill].sections[indexSec].offset_question++;
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question++;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_question++;
      vm.$set(vm.skills);
      vm.is_call_api = false;
      vm.dialogQuestion = false;
      setTimeout(() => {
        vm.$scrollTo("#question" + vm.indexSkill + vm.indexSection + vm.indexExercise + indexRowQuestion, 1000);
      }, 100);
    },
    updateWithFormData(data) {
      let vm = this;
      let formData = new FormData();
      formData.append("id", data.id);
      formData.append("exercise_id", data.exercise_id);
      formData.append("answer_id", data.answer_id);
      formData.append("exercise_type", data.exercise_type);
      formData.append("type", data.type);
      formData.append("title", data.title);
      formData.append("element_type", data.element_type);
      formData.append("element_data", data.element_data);
      formData.append("is_element", data.is_element);
      formData.append("is_required_script", data.is_required_script);
      formData.append("body", JSON.stringify(data.body));
      formData.append("_method", "PUT");
      if (!(data.element_id == null))
        formData.append("element_id", data.element_id);
      if (!(data.description == null))
        formData.append("description", data.description);
      if (!(data.explanation == null))
        formData.append("explanation", data.explanation);
      if (!(data.auto_marking_quota == null))
        formData.append("auto_marking_quota", data.auto_marking_quota);
      if (data.validation_rules) formData.append("validation_rules", JSON.stringify(data.validation_rules));
      if (data.idea) formData.append("idea", data.idea);
      if (data.sample) formData.append("sample", data.sample);
      if (data.audio_data) formData.append("audio_data", data.audio_data);
      if (data.audio_file) formData.append("audio_file", data.audio_file);
      if (data.time_to_think) formData.append("time_to_think", data.time_to_think);
      if (data.can_take_note) formData.append("can_take_note", data.can_take_note);
      if (data.limit_time) formData.append("limit_time", data.limit_time);
      if (data.limit_listen_time) formData.append("limit_listen_time", data.limit_listen_time);
      if (data.sample_data) formData.append("sample_data", data.sample_data);
      if (data.sample_file) formData.append("sample_file", data.sample_file);
      if (data.sample_vocabulary) formData.append("sample_vocabulary", data.sample_vocabulary);
      this.is_call_api = true;
      ApiService.post(
        "prep-app/test/draft/question/" + this.question_id,
        formData
      )
        .then(function (res) {
          if (res.status === 200) {
            data.audio_file = res.data.question.audio_file;
            data.sample_file = res.data.question.sample_file;
            vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[vm.indexQuestion] = data;
            vm.$set(vm.skills);
            vm.is_call_api = false;
            vm.dialogQuestion = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestion = false;
            vm.is_call_api = false;
            vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
          }
        });
    },
    isValueInvalid(value) {
      return value === "" || value === undefined || value === null;
    },
    async getIdeaGeneratorFromOpenAi() {
      let validateGenerator = this.ideaGeneratorValidator(this.skills[this.indexSkill], this.indexSection, this.indexExercise);
      if (!validateGenerator) {
        return;
      }

      let vm = this;
      let data = this.getParameterIdeaToGenerator();

      vm.is_call_api = true;
      await ApiService.post("prep-app/test/draft/question/get-idea-generator", data)
          .then(function (res) {
            if (res.status === 200) {
              if (!res.data) {
                vm.$toasted.error("Đã có lỗi xảy ra trong quá trình lấy gợi ý. Vui lòng thử lại sau! ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
              vm.idea_input = res.data;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422 || error.response.status === 409) {
              vm.$toasted.error(error.response.data.error.message, {theme: "toasted-primary", position: "top-right", duration: 4000,});
            } else if(error) {
              vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            }
            vm.is_call_api = false;
          });
    },
    async getSampleGeneratorFromOpenAi() {
      let validateGenerator = this.sampleGeneratorValidator(this.skills[this.indexSkill], this.indexSection, this.indexExercise);
      if (!validateGenerator) {
        return;
      }

      let vm = this;

      let data = this.getParameterSampleToGenerator();

      vm.is_call_api = true;
      await ApiService.post("prep-app/test/draft/question/get-sample-generator", data)
          .then(function (res) {
            if (res.status === 200) {
              if (!res.data) {
                vm.messError('Đã có lỗi xảy ra trong quá trình lấy gợi ý. Vui lòng thử lại sau');
              }
              vm.sample_input = res.data;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422 || error.response.status === 409) {
              vm.messError(error.response.data.error.message);
            } else if(error) {
              vm.messError('Something Wrong. Please try again or contact admin');
            }
            vm.is_call_api = false;
          });
    },
    allowedToUsePhonetic() {
      let flat = false;
      if (this.skills[this.indexSkill]) {
        if (this.skills[this.indexSkill]?.sections[this.indexSection]?.exercises[this.indexExercise]?.instant_feedback === 1) {
          flat = true;
        }
      }
      return flat;
    },
    openDiaLogAddPhonetic() {
      this.refTitleQuestion = this.$refs.refTitleQuestion.$refs.editorCustom;
      this.dialogAddPhoneticToTitleQuestion = true;
    },
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    getTimeLimitInputDefault() {
      let limit_time = null;
      if (this.product_category_id === this.product_category_constant.TOEIC) {
        if (this.skillCurrent === 'Writing' && this.skills[this.indexSkill].vwr === 1) {
          if (
              this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_2 &&
              this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.module === this.module_section_part.VWR
          ) {
            limit_time = "10:00";
          }
        }
      }
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_1) {
            limit_time = "00:30";
          }
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_2) {
            limit_time = "02:00";
          }
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_3) {
            limit_time = "00:45";
          }
        }
      }
      return limit_time;
    },
    getTimeToThinkInputDefault() {
      let time_to_think = null;
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_1) {
            time_to_think = "00:03";
          }
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_2) {
            time_to_think = "01:00";
          }
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_3) {
            time_to_think = "00:03";
          }
        }
      }
      return time_to_think;
    },
    getCanTakeNotInputDefault() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          if (this.skills[this.indexSkill].sections[this.indexSection]?.partConfig.offset === this.offset_section_part.PART_2) {
            flat = true;
          }
        }
      }
      return flat ? 1 : 0;
    },
    async btnGenAudioForTitleQuestion() {
      if (!this.inputTitleQuestion) {
        this.messError("Hãy nhập tiêu đề câu hỏi trước khi tạo audio", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        return;
      }
      this.audio_data_input = await this.actionGenAudioByHtml(this.inputTitleQuestion);
    },
    async btnGenAudioForSampleQuestion() {
      if (!this.sample_input) {
        this.messError("Hãy nhập sample câu hỏi trước khi tạo audio", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        return;
      }
      this.sample_data = await this.actionGenAudioByHtml(this.sample_input);
    },
    async actionGenAudioByHtml(html = '') {
      if (!html) {
        this.messError("Hãy nhập dữ liệu trước khi tạo audio", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        return;
      }
      let text = this.$utils.htmlToString(html);
      let data = {
        text,
      }
      let vm = this;

      try {
        vm.is_call_api = true;
        let res = await ApiService.post("prep-app/test/draft/question/text-to-speech", data);
        if (res.status === 200) {
          vm.is_call_api = false;
          return res.data;
        } else {
          vm.is_call_api = false;
          vm.messError("Đã có lỗi xảy tra trong quá trình gen audio");
          return null;
        }
      } catch (error) {
        vm.is_call_api = false;
        if (error.response.status === 422 || error.response.status === 409) {
          vm.messError(error.response.data.error.message);
        } else if(error) {
          vm.messError("Đã có lỗi xảy ra, vui lòng liên hệ với team Teach để được hỗ trợ");
        }
        return null;
      }

    },
    onAudioSampleFileChange(file) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.sample_data = null;
        vm.sample_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.sample_data = e.target.result;
        vm.sample_file = file;
      };
      reader.readAsDataURL(file);
    },
    onAudioFileChange(file) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.audio_file_input = null;
        vm.audio_data_input = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.audio_data_input = e.target.result;
        vm.audio_file_input = file;
      };
      reader.readAsDataURL(file);
    },
    canInputGenAudio() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          if(this.data_type_question === this.exercise_type_constant.RECORDING) {
            flat = true;
          }
        }
      }
      return flat;
    },
    canInputAudioSample() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          if(this.data_type_question === this.exercise_type_constant.RECORDING) {
            flat = true;
          }
        }
      }
      return flat;
    },
  },
};
</script>

<style lang="css" scoped></style>
